import {inject } from 'aurelia-framework';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { PerformanceMangemantService } from "../../../../shared/services/performancereviewservice";
import {config} from "../../../../shared/services/config";

@inject(DialogController,JwtService,PerformanceMangemantService,DialogService,)

export class EditAddAchivemetsPopup{
    AchievementErrorMessage=false;
    viewfile=true;
    AchievementComments='';
    ViewID=2;
    ValidatecheckerrorMessage=false;

    constructor(controller,JwtService,PerformanceMangemantService,DialogService){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.performanceMangemantService = PerformanceMangemantService;
    }
    activate(achievements){
        this.params=achievements;
        this.KRAtitle=this.params.KRAtitle;
        this.AchievementComments=this.params.achievements.Achievement;
        this.DocumentNameDisplay=this.params.achievements.DocumentNameDisplay;
        this.DocumentPath=this.params.achievements.DocumentPath;
        this.AchievementID=this.params.achievements.AchievementID;
        this.ReferenceID=this.params.achievements.ReferenceID;
        this.ReviewTypeID=this.params.achievements.ReviewTypeID;
        if (achievements.achievements.DocumentPath) {
            this.files = achievements.achievements.DocumentPath;
            this.DocumentName = achievements.achievements.DocumentPath // Set the desired file name
        }
    }
    attached(){
        this.specializationInput.focus();
        document.addEventListener('click', this.handleDocumentClick);


        this.performanceMangemantService.GetEditCreateAchievements(this.AchievementID,this.ViewID).then(data => {
            this.editdata=data;
        })
    }

    cancel(){
        this.controller.cancel();
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    handleFileSelect(files) {
        this.viewfile=false;
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNameDisplay = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }
    downloadFile() {
        if (this.files) {
            const downloadfile = config.img_url +this.DocumentPath;
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentNameDisplay; // Replace 'filename' with the desired name of the downloaded file
            link.target = '_blank';
            link.click();
        }
    }
    removeFile() {
        this.files = null;
        this.DocumentNameDisplay = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    submitAchievement() {
        const addAchievementdata = {
            EmployeeID: this.empid,
            ReferenceID: this.ReferenceID,
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
            Achievement: this.AchievementComments,
            DocumentName: this.DocumentNameDisplay,
            DocumentPath: this.files,
            AchievementID:this.editdata.AchievementID
        };

        const AddAchivementValidatecheck={
            AchievementID:this.editdata.AchievementID,
            ReferenceID: this.ReferenceID,
            Achievement: this.AchievementComments,
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
        };
        if (this.AchievementComments == null || this.AchievementComments == "" || this.AchievementComments == undefined) {
            this.AchievementErrorMessage = true;
        }
        else if (this.AchievementComments!= null||this.AchievementComments!=""||this.AchievementComments!=undefined) {
            this.performanceMangemantService.CheckDuplicateAchievement(AddAchivementValidatecheck).then(Validatecheck => {
                if (Validatecheck === true) {
                    this.ValidatecheckerrorMessage=true;
                }else {
                    this.AchievementErrorMessage = false;
                    this.ValidatecheckerrorMessage=false;
                    this.performanceMangemantService.addAchievements(addAchievementdata).then(data => {
                        this.controller.ok(true);
                    })
                }
            })}
    }
    detached(){
        document.removeEventListener('click', this.handleDocumentClick);
    }
    handleTextareaBlur(){
        if (!this.AchievementComments.trim()) {
            this.AchievementErrorMessage = true;
        } else {
            this.AchievementErrorMessage = false;
        }
    }
    handleDocumentClick = (event) => {
        if (event.target == this.specializationInput) {
            this.AchievementErrorMessage  = false; // Hide the error message when clicking outside the textarea
        }
    };
}

import {inject} from 'aurelia-framework';
import {JwtService} from '../../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {PerformanceMangemantService} from "../../../../shared/services/performancereviewservice";
import {config} from "../../../../shared/services/config";
import {AddAchivemetsPopup} from "../addachivemetsPopup/addachivemetsPopup";
import flatpickr from "flatpickr";
import {AlertpopupComponent} from "../../../alertpopup/alertpopupcomponent";
import {EditAddAchivemetsPopup} from "../editaddachivementspopup/editaddachivementspopup";

@inject(DialogController, JwtService, PerformanceMangemantService, DialogService)
export class AchivemetsPopup {
    isLoading = false;
    static inject = [DialogController, Element];
    isDragging = false;
    dialog;
    pageSize = config.kra_PazeSize;
    originalTargetCompletionDate = null;
    TargetCompletionDateTxt = null;

    constructor(controller, JwtService, PerformanceMangemantService, DialogService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.performanceMangemant = PerformanceMangemantService;
    }

    activate(AchivemetsData) {
        this.params = AchivemetsData;
        this.ReviewTypeID = this.params.ReviewTypeID;
        this.ViewID = this.params.ViewID;
        this.ReferenceID = this.params.referenceID;
        this.Category = this.params.Category,
            this.KRAtitle = this.params.KRAtitle,
            this.MeasurementCriteria = this.params.MeasurementCriteria,
            this.TargetCompletionDateTxt = this.params.TargetCompletionDateTxt,
            this.originalTargetCompletionDate = this.TargetCompletionDateTxt;
        this.EmployeeCategoryID = this.params.EmployeeCategoryID,
            this.EmployeeGoalID = this.params.EmployeeGoalID,
            this.RecordStatusCode = this.params.RecordStatusCode,
            this.ReviewFromDateText = this.params.ReviewFromdate,
            this.ReviewToText = this.params.ReviewToText,
            this.MarkasComplete = this.params.MarkasComplete,
            this.viewTypeId = this.params.viewTypeId,
            this.GoalStatusTxt = this.params.GoalStatusTxt
    }

    attached() {

        this.isLoading = true;
        flatpickr(this.datePickers, {
            dateFormat: 'd-m-Y',
            minDate: 'today',
            maxDate: this.ReviewToText,
            defaultDate: this.TargetCompletionDateTxt,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            onClose: function (selectedDates, dateStr, instance) {
            }
        });
        this.calendarIcons.addEventListener('click', () => {
            this.datePickers._flatpickr.open();
        });
        this.performanceMangemant.GetAchievements(this.EmployeeGoalID, this.ReviewTypeID, this.ViewID).then(data => {
            this.getAchievements = data;
            this.Files = this.getAchievements.map(option => option.DocumentPath);
            if (this.getAchievements.map(option => option.DocumentPath)) {
                this.file = this.getAchievements.map(option => option.DocumentPath);
                this.DocumentName = this.getAchievements.map(option => option.DocumentPath);
            }
            this.isLoading = false;
        });
    }

    cancel() {
        this.controller.cancel();
    }

    downloadFile(DocumentPath) {
        const downloadfile = config.img_url + DocumentPath;
        const link = document.createElement('a');
        link.href = downloadfile;
        link.download = this.DocumentPath;
        link.target = '_blank';
        link.click();
    }

    AddAchivemetsPopup(KRAtitle) {

        const addAchivementsdata = {
            KRAtitle: KRAtitle,
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
            ReferenceID: this.EmployeeGoalID
        };
        this.dialogService.open({viewModel: AddAchivemetsPopup, model: addAchivementsdata}).whenClosed((feedback) => {
            if (!feedback.wasCancelled) {
                this.performanceMangemant.GetAchievements(this.EmployeeGoalID, this.ReviewTypeID, this.ViewID)
                    .then(data => {
                        this.getAchievements = data;
                    });
            }
        })
    }

    editAddachivemetsPopup(achievements, KRAtitle) {
        const editAchivementsdata = {
            achievements: achievements,
            KRAtitle: KRAtitle
        }
        this.dialogService.open({viewModel: EditAddAchivemetsPopup, model: editAchivementsdata})
            .whenClosed((feedback) => {
                if (!feedback.wasCancelled) {
                    this.performanceMangemant.GetAchievements(this.EmployeeGoalID, this.ReviewTypeID, this.ViewID)
                        .then(data => {
                            this.getAchievements = data;
                        });
                }
            })
    }

    async DeleteaddachivemetsPopup(achievements) {
        const data = {
            AchievementID: achievements.AchievementID,
            ViewID: this.ViewID,
            EmployeeID: this.empid
        };
        const result = await this.dialogService.open({viewModel: AlertpopupComponent, model: achievements,})
            .whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        await this.performanceMangemant.DeleteAchievements(data).then(data => {
            this.delete = data;

            this.performanceMangemant.GetAchievements(this.EmployeeGoalID, this.ReviewTypeID, this.ViewID).then(data => {
                this.getAchievements = data;
            });
        });

    }

    SubmitAchievements() {

        const data = {
            EmployeeGoalID: this.EmployeeGoalID,
            EmployeeCategoryID: this.EmployeeCategoryID,
            EmployeeID: this.empid,
            TargetCompletionDateTxt: this.TargetCompletionDate !== undefined && this.TargetCompletionDate !== null ? this.TargetCompletionDate : this.originalTargetCompletionDate,
        };
        this.performanceMangemant.Achievementsdata(data).then(data => {
            this.controller.ok(true);
        })

    }

}

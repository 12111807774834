import {inject} from 'aurelia-framework';
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {PerformanceMangemantService} from "../../../shared/services/performancereviewservice";
import {config} from "../../../shared/services/config";
import {AchivemetsPopup} from "./achivemetsPopup/achivemetsPopup";
import {Markascompletemodel} from "./markascompleteconformationpopup/markascompletemodel";

@inject(DialogController, JwtService, DialogService, PerformanceMangemantService)
export class PerformanceReview {
    pageSize = config.kra_PazeSize;
    topicsPageSize = config.kra_PazeSize;
    ViewID = 2;
    isOpen = null;
    Successmessages = false;
    kraCategories = [];
    markascompleted = false;
    markascompletachivementpopup = false;
    kraCategories = [];
    selectedDiv = 0;
  activeTab = 'tab1';

  performanceClick() {
    event.preventDefault();
    const parentDiv = event.target.closest('.parent-div');
    if (parentDiv) {
      parentDiv.classList.toggle('performance-open');
    }
  }

  activateTab(tab) {
    this.activeTab = tab;
  }

    constructor(controller, JwtService, DialogService, PerformanceMangemantService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.dialogService = DialogService;
        this.performaceService = PerformanceMangemantService;
    }

    activate(approveData) {
        this.params = approveData;
        this.ActionName = this.params.ActionName,
            this.RecordStatusCode = this.params.RecordStatusCode,
            this.PeriodID = this.params.PeriodID,
            this.KRAPerformanceReviewID = this.params.KRAPerformanceReviewID,
            this.ReviewTypeID = this.params.ReviewTypeID,
            this.ReviewFromText = this.params.ReviewFromText,
            this.ReviewToText = this.params.ReviewToText
    }

    attached() {
        this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
            .then(data => {
                this.kraCategories = data.EmployeeCategoryList;
                if (this.kraCategories.length > 0) {
                    const defaultCategory = this.kraCategories[0]; // Change this to the desired category
                    this.toggleDisplay(defaultCategory);
                }
            });
        this.performaceService.GetReviewHistory(this.empid, this.PeriodID, this.ReviewTypeID)
            .then(data => {
                this.GetReviewHistory = data;
            });

        this.performaceService.GetAdditionalNotesandComments(this.KRAPerformanceReviewID)
            .then(data => {
                this.additionalcomments = data;
                this.Files = this.additionalcomments.map(option => option.DocumentPath);
                if (this.additionalcomments.map(option => option.DocumentPath)) {
                    this.file = this.additionalcomments.map(option => option.DocumentPath);
                    this.DocumentName = this.additionalcomments.map(option => option.DocumentPath);
                }
            });
    }

    /*activateTab(index) {
        this.selectedDiv = index;
        this.kraCategories.forEach((category, idx) => {
            category.showDiv = (index === idx);
        });
    }*/

    markAsComplete(event, EmployeeGoalID, MarkAsCompletedIND, category) {
        const result = this.dialogService.open({
            viewModel: Markascompletemodel,
            model: '',
        }).whenClosed(response => {
            if (response.wasCancelled) {
                this.markascompleted = false;
                return;
            } else {
                this.markascompleted = true;
                this.markascompletachivementpopup = true;
                const checkbox = document.querySelector(`input[name="achievement_${MarkAsCompletedIND == true}"]`);
                if (checkbox) {
                    checkbox.checked = true;
                    const markascompletedata = {
                        EmployeeGoalID: EmployeeGoalID,
                        EmployeeID: this.empid
                    };
                    this.performaceService.MarkasCompletedKraCategories(markascompletedata)
                        .then(data => {
                            this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
                                .then(data => {
                                    this.kraCategories = data.EmployeeCategoryList;
                                    const result = this.kraCategories?.find(item => item.EmployeeCategoryID === category.EmployeeCategoryID);
                                    const defaultCategory = result;
                                    this.toggleDisplay(defaultCategory);
                                });


                        });
                }
            }
        })
    }

    AchivemetsPopup(referenceID, Categorys, Goal, MeasurementCriteria, TargetCompletionDateTxt, EmployeeCategoryID, EmployeeGoalID, MarkAsCompletedIND, GoalStatusTxt, category) {
        if (this.markascompletachivementpopup == true) {
            this.markascompleted = this.markascompleted;
        } else {
            this.markascompleted = MarkAsCompletedIND;
        }
        const AchivemetsData = {
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
            referenceID: referenceID,
            Category: Categorys,
            KRAtitle: Goal,
            MeasurementCriteria: MeasurementCriteria,
            TargetCompletionDateTxt: TargetCompletionDateTxt,
            EmployeeCategoryID: EmployeeCategoryID,
            EmployeeGoalID: EmployeeGoalID,
            RecordStatusCode: this.RecordStatusCode,
            ReviewFromdate: this.ReviewFromText,
            ReviewToText: this.ReviewToText,
            MarkasComplete: this.markascompleted,
            GoalStatusTxt: GoalStatusTxt,
        };
        this.dialogService.open({viewModel: AchivemetsPopup, model: AchivemetsData})
            .whenClosed((feedback) => {
                    if (!feedback.wasCancelled) {
                        this.messages = "KRA updated successfully.";
                        this.Successmessages = true;
                        setTimeout(() => {
                            this.messages = null
                        }, 5000);
                        this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
                            .then(data => {
                                this.kraCategories = data.EmployeeCategoryList;
                                if (this.kraCategories.length > 0) {
                                    const result = this.kraCategories?.find(item => item.EmployeeCategoryID === category.EmployeeCategoryID);
                                    const defaultCategory = result;
                                    this.toggleDisplay(defaultCategory);
                                }
                            });
                    }
                }
            )
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    toggleDisplay(category) {
        this.kraCategories.forEach(cat => {
            if (cat !== category) {
                cat.showDiv = false;
            }
        });
        category.showDiv = true;
    }

    AcceptRequest() {
        if (this.fileMessage) {
            return false;
        }
        let AcceptrequestData = {
            EmployeeID: this.empid,
            WorkflowComments: this.WorkflowComments,
            DocumentPath: this.files,
            DocumentName: this.DocumentNames,
            KRAPerformanceReviewID: this.KRAPerformanceReviewID,
            PeriodID: this.PeriodID,
            ReviewTypeID: this.ReviewTypeID,
            Actions: "Accept"
        };
        this.performaceService.AcceptKraMyemployee(AcceptrequestData).then(data => {
            const datas = {
                message: true
            };
            const queryString = new URLSearchParams(datas).toString();
            const performancereviewURL = `/#/KRAPerformanceReview/MyGoalSheet?${queryString}`;
            window.location.href = performancereviewURL;
        });
    }

    openCategory(index) {
        this.isOpen = index;
    }

    kraClick() {
        event.preventDefault();
        const clickedDivopen = event.target.closest('div.goalSection > div');
        if (clickedDivopen) {
            clickedDivopen.classList.toggle('content-open');
        }
    }

    BackPage() {
        const performancereviewURLBackPage = `/#/KRAPerformanceReview/MyGoalSheet`;
        window.location.href = performancereviewURLBackPage;
    }


}

import {inject,BindingEngine } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import 'flatpickr/dist/flatpickr.min.css';
import { JwtService } from '../../../../shared/services/jwtservice';
import {PerformanceMangemantService} from "../../../../shared/services/performancereviewservice";
import {KraPerformanceReviewService} from "../../../../shared/services/kraperformancereviewservice";

@inject(DialogController,PerformanceMangemantService,JwtService,KraPerformanceReviewService)
export class AddKraPopupModal {
    selectedOption = 'createNew';
    selectedKRARepository = null;
    isDragging = false;
    dialog;
    static inject = [Element];
    uploadedFiles = [];
    changedMode = 'true';
    pageSize = 5;
    ErrorMessageShow = false;
    isSubmitting = false;


    constructor(DialogController, PerformanceMangemantService, JwtService, KraPerformanceReviewService, bindingEngine) {
        this.controller = DialogController;
        this.performancereviewservice = PerformanceMangemantService;
        this.KraPerformanceReviewService = KraPerformanceReviewService;
        this.jwtService = JwtService;
        this.bindingEngine=bindingEngine

        this.EmpID = this.jwtService.getEmployeeId();
    }

    activate(data) {
        //this.EmployeePerformanceReviewID = data.EmployeePerformanceReviewID;
        this.EmployeePerformanceReviewID = 2;

    }

    attached() {

        this.KraPerformanceReviewService.KraCategoryDropdown().then(data => {
            this.categoryDropdown = data.CategoryList;
                //.map(category => category.CategoryName);

            console.log(this.categoryDropdown);
        })
        this.KraPerformanceReviewService.KRARepository().then(data => {
            this.KRARepository = data;
            console.log(this.KRARepository);
        })


    }

    fileValidator() {
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage = "Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage = null;
                this.fileValidatorRule = false;
            }
        });
    }

    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        const allowedFormats = ['.jpg', '.png', '.doc', '.pdf'];
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if (allowedFormats.includes(fileExtension)) {
            const reader = new FileReader();
            reader.onload = (event) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                this.documentFileName = file.name;
                reader.onload = () => {
                    this.file = reader.result;
                };
            };
            reader.readAsDataURL(file);
        } else {
            // Handle invalid file format here (e.g., display an error message)
            this.uploadErrors = [{error: {message: ''}}];

        }
    }

    // handleFileSelect(files) {
    //     const file = files[0];
    //     this.file = file;
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     this.DocumentName = file.name;
    //     reader.onload = () => {
    //         this.file = reader.result;
    //     };
    // }
    openFileDialog() {
        document.getElementById('attachments').click();
    }

    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }


    rowSelected(KRARepositorydata) {

             this.selectedKRARepository = KRARepositorydata;

        // Update the input fields based on the selected row data
        this.kratitleRepository = this.selectedKRARepository.Parameter;
        this.selectedCategoryRepository = this.selectedKRARepository.CategoryName;
        console.log(this.selectedCategoryRepository);
        this.CriteriaRepository = this.selectedKRARepository.MeasurementCriteria;
        this.WeightageRepository = this.selectedKRARepository.Weightage;
        this.bindingEngine.propertyObserver(this, 'selectedKRARepository').subscribe(() => {});
    }
    // Sample data structure



    KRARepositorySubmit() {
        const refreshEvent = new CustomEvent('refreshExemptData');
        event.preventDefault();
        const Data = {
            EmployeeID: this.EmpID,
            EmployeePerformanceReviewID: this.EmployeePerformanceReviewID,
            KRATitle:this.kratitleRepository,
            CategoryID:this.k,
            Criteria:this.WeightageRepository,
            Weightage:this.WeightageRepository,

        };
        this.KraPerformanceReviewService.KRARepositoryPost(Data)
            .then(data => {
                //this.controller.cancel();
                });

    }

    cancel() {
        this.controller.cancel();
    }

    CreateNewKRASubmit(){
        event.preventDefault();
       // this.controllerFactory.validate()
           // .then(result => {
            //    if (result.valid) {
                    const data = {
                        EmployeeID: this.EmpID,
                        EmployeePerformanceReviewID: this.EmployeePerformanceReviewID,
                        KRATitle:this.kratitle,
                        CategoryID:this.selectedCategory,
                        Criteria:this.Criteria,
                        Weightage:this.Weightage,

                    };
                    console.log(data);
                    this.KraPerformanceReviewService.PostCreateKRA(data)
                        .then(data => {
                            //this.controller.cancel();
                        });
              //  }
           // });

    }
}


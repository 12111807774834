import { DialogController } from 'aurelia-dialog';
export class Markascompletemodel {
  static inject = [DialogController,Element];
  isDragging = false;
    dialog;
    constructor(controller) {
      this.controller = controller;
      document.addEventListener('keydown', this.handleKeyPress);
  }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.controller.cancel();
        }
    };
  cancel() {
     this.controller.cancel();
  }
  ok() {
     this.controller.ok();
  }

    detached() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}

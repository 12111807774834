import {inject} from 'aurelia-framework';
import {JwtService} from '../../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {PerformanceMangemantService} from "../../../../shared/services/performancereviewservice";

@inject(DialogController, JwtService, PerformanceMangemantService, DialogService,)
export class AddAchivemetsPopup {
    AchievementErrorMessage = false;
    ValidatecheckerrorMessage=false;
    AchievementComments = '';

    constructor(controller, JwtService, PerformanceMangemantService, DialogService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.performanceMangemantService = PerformanceMangemantService;
    }

    activate(addAchivementsdata) {
        this.params = addAchivementsdata;
        this.KRAtitle=this.params.KRAtitle;
        this.ReviewTypeID = this.params.ReviewTypeID;
        this.ViewID = this.params.ViewID;
        this.ReferenceID = this.params.ReferenceID;
    }

    attached() {
        this.specializationInput.focus();
        document.addEventListener('click', this.handleDocumentClick);
    }

    cancel() {
        this.controller.cancel();
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    submitAchievement() {
        const addAchievementdata = {
            EmployeeID: this.empid,
            ReferenceID: this.ReferenceID,
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
            Achievement: this.AchievementComments,
            DocumentName: this.DocumentNames,
            DocumentPath: this.files,
        };

        const AddAchivementValidatecheck={
            AchievementID:0,
            ReferenceID: this.ReferenceID,
            Achievement: this.AchievementComments,
            ReviewTypeID: this.ReviewTypeID,
            ViewID: this.ViewID,
    };
        if (this.AchievementComments == null || this.AchievementComments == "" || this.AchievementComments == undefined) {
            this.AchievementErrorMessage = true;
        }
        else if (this.AchievementComments!= null||this.AchievementComments!=""||this.AchievementComments!=undefined) {
            this.performanceMangemantService.CheckDuplicateAchievement(AddAchivementValidatecheck).then(Validatecheck => {
                if (Validatecheck === true) {
                    this.ValidatecheckerrorMessage=true;
                }else {
                    this.AchievementErrorMessage = false;
                    this.ValidatecheckerrorMessage=false;
                    this.performanceMangemantService.addAchievements(addAchievementdata).then(data => {
                        this.controller.ok(true);
                    })
                }
            })}
    }

    detached() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    handleTextareaBlur() {
        if (!this.AchievementComments.trim()) {
            this.AchievementErrorMessage = true;
        } else {
            this.AchievementErrorMessage = false;
        }
    }

    handleDocumentClick = (event) => {
        if (event.target == this.specializationInput) {
            this.AchievementErrorMessage = false; // Hide the error message when clicking outside the textarea
        }
    };
}
